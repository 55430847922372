import isRequired from '@/components/form/validations/isRequired'
import FieldText from '@/components/forms/FieldText'
import FieldSelect from '@/modules/agencies/components/FieldAgencyType'

export default (isEdit) => ({
  class: 'col',
  panels: [{
    class: 'col q-py-sm q-px-xs',
    panels: [{
      label: 'Informações do Orgão Emissor',
      class: 'col-12 bg-white border-radius-4 border-box q-pa-sm',
      fields: [{
        name: 'name',
        label: 'Nome',
        class: 'col-sm-12',
        component: FieldText,
        placeholder: 'Nome',
        isRequired: true,
        rules: [isRequired()]
      },
      {
        name: 'type',
        label: 'Tipo',
        class: 'col-sm-6',
        component: FieldSelect,
        placeholder: 'Tipo',
        isRequired: true,
        rules: [isRequired()]
      }]
    }]
  }]
})
