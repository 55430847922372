<template>
  <FormRender
    v-model="dataDocument"
    :form="form"
    :is-edit="isEdit"
    :get-url="getEndpoint"
    :save-url="saveEndpoint"
    :on-saved="handlerSaved"
    :success-message="successMessage"
  />
</template>

<script>
import configForm from '../config/form'
import FormRender from '@/components/form/FormRender'

import {
  ROUTE_AGENCY_EDIT,
  ROUTE_AGENCIES
} from '../constants'

export default {
  components: {
    FormRender
  },

  data () {
    const isEdit = this.$route.name === ROUTE_AGENCY_EDIT
    const agencyId = isEdit ? this.$route.params.id : null
    const getEndpoint = isEdit ? `/agencies/${agencyId}` : ''
    const saveEndpoint = isEdit ? `/agencies/${agencyId}` : '/agencies'
    const form = configForm(isEdit)
    const successMessage = (isEdit ? 'Orgão Emissor salvo com sucesso!' : 'Orgão Emissor cadastrado com sucesso!')
    const dataDocument = { }

    return {
      form,
      isEdit,
      getEndpoint,
      dataDocument,
      saveEndpoint,
      successMessage
    }
  },

  mounted () {
    const vm = this
    const isGeneralRole = vm.$store.getters.isGeneralRole
    if (!isGeneralRole) return vm.$router.go(-1)
  },

  methods: {
    handlerSaved () {
      this.$router.push({ name: ROUTE_AGENCIES })
    }
  }
}

</script>
